import { getDefaultPath } from "../../helpers/urlSync";
import { getView } from "../actions/app_action";
import * as actionTypes from "../actions/actionTypes";
import { APP_ENV } from "../../settings";

const preKeys = getDefaultPath();
const INITIAL_STATE = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  app_connection: true,
  env: APP_ENV,
  isLoading: false,
  field_errors:[]
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.COLLPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed };
    case actionTypes.COLLPSE_OPEN_DRAWER:
      return { ...state, openDrawer: !state.openDrawer };
    case actionTypes.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height: height
        };
      }
      break;
    case actionTypes.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case actionTypes.CHANGE_CURRENT:
      return { ...state, current: action.current };
    case actionTypes.CLOSE_ALL:
      return { ...state, current: [], openKeys: [] };
    case actionTypes.RESET_APP_STATE:
      return { ...INITIAL_STATE };
    case actionTypes.NO_INTERNET:
      return { ...state, app_connection: false };
    case actionTypes.SET_ENVIRONMENT:
      return { ...state, env: action.env };
    case actionTypes.ENABLE_LOADING:
      return { ...state, enableLoading: true };
    case actionTypes.DISABLE_LOADING:
      return { ...state, enableLoading: false, isLoading: false };
      case actionTypes.SET_LOADING:
        return { ...state, isLoading: true };
      
    case actionTypes.HANDLE_API_ERROR:
      return { ...state, field_errors: [] };
      
    case actionTypes.FIELD_ERROR_HANDLE:
      return { ...state, field_errors: action.message };
    default:
      return state;
  }
  return state;
}
