import * as actionTypes from "./actionTypes";


export const loadStateDistrictList = (data) => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST
  };
};

export const loadStateDistrictListSuccess = data => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS,

    data
  };
};


export const loadLanguages = (data) => {
  return {
    type: actionTypes.LOAD_LANGUAGUES,
    data
  };
};

export const loadLanguagesSuccess = data => {

  return {
    type: actionTypes.LOAD_LANGUAGUES_SUCCESS,
    data
  };
};


export const loadLanguagesFail = () => {
  return {
    type: actionTypes.LOAD_LANGUAGUES_FAIL
  };
};



export const loadExpressRecyclingList = (data) => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST,
    data
  };
};

export const loadExpressRecyclingListSuccess = data => {

  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_SUCCESS,
    data
  };
};


export const loadExpressRecyclingListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_FAIL
  };
};


export const loadRecyclingList = (data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST,
    data
  };
};

export const loadRecyclingListSuccess = data => {

  return {
    type: actionTypes.LOAD_RECYCLING_LIST_SUCCESS,
    data
  };
};


export const loadRecyclingListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_FAIL
  };
};





export const loadRecyclingCategory = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY,
    data
  };
};

export const loadRecyclingCategorySuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_SUCCESS,
    data
  };
};

export const loadRecyclingCategoryFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_FAIL
  };
};


export const loadRecyclingExtraFee = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE,
    data
  };
};

export const loadRecyclingExtraFeeSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE_SUCCESS,
    data
  };
};

export const loadRecyclingExtraFeeFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE_FAIL
  };
};



export const loadRecyclingDumpster = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_DMPSTER,
    data
  };
};

export const loadRecyclingDumpsterSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_DMPSTER_SUCCESS,
    data
  };
};

export const loadRecyclingDumpsterFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_DMPSTER_FAIL
  };
};


export const loadDumpsterService = data => {
  
  return {
    type: actionTypes.LOAD_DMPSTER_SERVICE,
    data
  };
};

export const loadDumpsterServiceSuccess = data => {
  return {
    type: actionTypes.LOAD_DMPSTER_SERVICE_SUCCESS,
    data
  };
};

export const loadDumpsterServiceFail = () => {
  return {
    type: actionTypes.LOAD_DMPSTER_SERVICE_FAIL
  };
};



//Carbon Footprint
export const loadImpactCarbon = data => {
  
  return {
    type: actionTypes.LOAD_IMPACT_CARBON,
    data
  };
};

export const loadImpactCarbonSuccess = data => {
  return {
    type: actionTypes.LOAD_IMPACT_CARBON_SUCCESS,
    data
  };
};

export const loadImpactCarbonFail = () => {
  return {
    type: actionTypes.LOAD_IMPACT_CARBON_FAIL
  };
};


export const getImpactCarbonCert = data => {
  
  return {
    type: actionTypes.GET_IMPACT_CARBON_CERT,
    data
  };
};

export const getImpactCarbonCertSuccess = data => {
  return {
    type: actionTypes.GET_IMPACT_CARBON_CERT_SUCCESS,
    data
  };
};

export const getImpactCarbonCertFail = () => {
  return {
    type: actionTypes.GET_IMPACT_CARBON_CERT_FAIL
  };
};
