import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  orderHistoryList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  orderHistoryListFilter: {},
  isLoadingOrderHistoryList: false,
  isLoadOrderHistoryListDone: false,
  isRefreshingOrderHistoryList: false,
  isLoadingOrderUpdate: false,  
  
  orderHistoryCount: {},
  isLoadingOrderHistoryCount: false,

  orderHistoryDetail: {},
  isLoadingOrderHistoryDetail: false,

  proceedRegisterUser: false,
  userExist:false,
  userExistDetail: null,
  userNotFound: false,
  pendingWalkInOrder: null,
  isLoadingPendingOrder: false,

  pendingWalkInOrderDetail: {},
  isLoadingWalkInOrderDetail: false,
  isWalkInOrderDetailUpdated: false,

  isPlaceWalkInOrderSuccess: false,
  isPlaceWalkInOrderFailed: false,
  isProcessPlaceWalkInOrder: false,

  isWalkInOrderCancelSuccess: false,
  isWalkInOrderCancelFailed: false,
  isProcessCancelWalkInOrder: false,

  isSetWalkInOrderPaymentMethodSuccess: false,
  isSetWalkInOrderPaymentMethodFailed: false,
  isProcessisSetWalkInOrderPaymentMethod: false,   

  isWalkInOrderCompleteSuccess: false,
  isWalkInOrderCompleteFailed: false,
  isProcessCompleteWalkInOrder: false,
  
  isGradeAgainWalkInOrderSuccess: false,
  isGradeAgainWalkInOrderFailed: false,
  isProcessGradeAgainCompleteWalkInOrder: false,  

  isWalkInOrderRecyclingAddSuccess: false,
  isWalkInOrderRecyclingAddFailed: false,
  isProcessAddOrderRecycling: false,
  walkInOrderRecyclingPriceUpdNotif: null,
  isWalkInOrderRecyclingUpdateSuccess: false,
  isWalkInOrderRecyclingUpdateFailed: false,
  isProcessUpdateOrderRecycling: false,

  isWalkInOrderRecyclingRemoveSuccess: false,
  isWalkInOrderRecyclingRemoveFailed: false,
  isProcessRemoveOrderRecycling: false,

  isWalkInOrderSetZoneSuccess: false,
  isProcessWalkInOrderSetZone: false,

  isWalkInOrderCategorySetZoneSuccess: false,
  isProcessWalkInOrderCategorySetZone: false,
  
  isCancelCompletedWalkInOrderSuccess: false,
  isCancelCompletedWalkInOrderFailed: false,
  isProcessCancelCompletedWalkInOrder: false,        
  newWalkInOrderID: null,
  
  gradingTaskList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
  },
  isLoadingGradingTaskList: false,
  isRefreshingGradingTaskList: false,

  gradingTaskDetail: {},
  isLoadingGradingTaskDetail: false,

  isGradingTaskAcceptSuccess: false,
  isProcessAcceptGradingTask: false,

  isGradingTaskRejectSuccess: false,
  isProcessRejectGradingTask: false,

  isGradingTaskSetZoneSuccess: false,
  isProcessGradingTaskSetZone: false,
  
  isGradingTaskCategorySetZoneSuccess: false,
  isProcessGradingTaskCategorySetZone: false,

  isGradingTaskRecyclingAddSuccess: false,
  isProcessAddGradingTaskRecycling: false,
  gradingTaskRecyclingPriceUpdNotif: null,

  isGradingTaskRecyclingUpdateSuccess: false,
  isProcessUpdateGradingTaskRecycling: false,

  isGradingTaskRecyclingRemoveSuccess: false,
  isProcessRemoveGradingTaskRecycling: false,
  
  isGradingTaskDocumentUploadSuccess: false,
  isProcessUploadGradingTaskDocument: false,

  isGradingTaskCompleteSuccess: false,
  isProcessCompleteGradingTask: false,
  
  settlementClosed: {status: false, title: "", msg: ""},
  
  isRegisterUserSuccess: false,
  isProcessRegisterUser: false,
  
  isProcessScanQR: false,
  isScanQRSuccess: false,
  isScanQRFailed: false,
  QRUserDetail: {},

  isProcessStartGrading: false,      
  isStartGradingSuccess: false,
  isStartGradingFailed: false,

  wasteGradingTaskList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
  },
  isLoadingWasteGradingTaskList: false,
  isRefreshingWasteGradingTaskList: false,

  wasteGradingTaskDetail: {},
  isLoadingWasteGradingTaskDetail: false,
  
  isWasteGradingTaskDocumentUploadSuccess: false,
  isProcessUploadWasteGradingTaskDocument: false,

  isWasteGradingTaskCompleteSuccess: false,
  isProcessCompleteWasteGradingTask: false,
};

export default function OrderReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.GET_SETTLEMENT_STATUS: {
      return {
        ...state,
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.GET_SETTLEMENT_STATUS_SUCCESS: {
      
      return {
        ...state,
        settlementClosed: {status: action.data.settlement_closed, title: action.data.title, msg: action.data.msg},
      };
    }

    case actionTypes.GET_SETTLEMENT_STATUS_FAIL: {
      return {
        ...state,
      };
    }
    
    /* ===== DUMP EXPRESS GRADING  ===== */
    case actionTypes.SCAN_USER_QR: {
      return {
        ...state,
        isProcessScanQR: true,
        isScanQRSuccess: false,
        isScanQRFailed: false,
        QRUserDetail: {},
      };
    }

    case actionTypes.SCAN_USER_QR_SUCCESS: {
      
      return {
        ...state,
        isProcessScanQR: false,
        isScanQRSuccess: true,
        QRUserDetail: (action.data && action.data.status) ? action.data.user : {},
      };
    }

    case actionTypes.SCAN_USER_QR_FAIL: {
      return {
        ...state,
        isProcessScanQR: false,
        isScanQRFailed: true,
      };
    }

    
    case actionTypes.START_GRADING: {
      return {
        ...state,
        isProcessStartGrading: true,      
        isStartGradingSuccess: false,
        isStartGradingFailed: false,
        pendingWalkInOrderDetail: {},
      };
    }

    case actionTypes.START_GRADING_SUCCESS: {
      
      return {
        ...state,
        isProcessStartGrading: false,   
        isStartGradingSuccess: true,
        pendingWalkInOrderDetail: (action.data.order) ? action.data.order : {},
      };
    }

    case actionTypes.START_GRADING_FAIL: {
      return {
        ...state,
        isProcessStartGrading: false,
        isStartGradingFailed: false,
      };
    }
    /* ===== DUMP EXPRESS GRADING  ===== */

    /* ===== WALK IN ORDER  ===== */
    case actionTypes.PLACE_WALKIN_ORDER: {
      return {
        ...state,
        isPlaceWalkInOrderSuccess: false,
        isPlaceWalkInOrderFailed: false,
        pendingWalkInOrderDetail: {},
        proceedRegisterUser: false,        
        isProcessPlaceWalkInOrder: true,        
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.PLACE_WALKIN_ORDER_SUCCESS: {
      
      return {
        ...state,
        isPlaceWalkInOrderSuccess: (action.data && action.data.user_exist) ? false : true,
        pendingWalkInOrderDetail: (action.data.order) ? action.data.order : {},
        proceedRegisterUser: (action.data.register_user) ? true : false,
        isProcessPlaceWalkInOrder: false,
        userExist: (action.data && action.data.user_exist) ? true : false,
      };
    }

    case actionTypes.PLACE_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isPlaceWalkInOrderFailed: true,
        isProcessPlaceWalkInOrder: false,
      };
    }

    

    case actionTypes.GET_PENDING_WALKIN_ORDER: {
      return {
        ...state,
        pendingWalkInOrder: null,
        isLoadingPendingOrder: true,
      };
    }

    case actionTypes.GET_PENDING_WALKIN_ORDER_SUCCESS: {
      return {
        ...state,
        pendingWalkInOrder: action.data,
        isLoadingPendingOrder: false,
      };
    }

    case actionTypes.GET_PENDING_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isLoadingPendingOrder: false,
      };
    }

    

    case actionTypes.CANCEL_WALKIN_ORDER: {
      return {
        ...state,
        isWalkInOrderCancelSuccess: false,
        isWalkInOrderCancelFailed: false,
        isProcessCancelWalkInOrder: true,
      };
    }

    case actionTypes.CANCEL_WALKIN_ORDER_SUCCESS: {
      return {
        ...state,
        pendingWalkInOrder: null,
        pendingWalkInOrderDetail: {},
        isWalkInOrderCancelSuccess: true,
        isProcessCancelWalkInOrder: false,
      };
    }

    case actionTypes.CANCEL_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isWalkInOrderCancelFailed: true,
        isProcessCancelWalkInOrder: false,
      };
    }
    
    

    case actionTypes.SET_WALKIN_ORDER_PAYMENT_METHOD: {
      return {
        ...state,
        isSetWalkInOrderPaymentMethodSuccess: false,
        isSetWalkInOrderPaymentMethodFailed: false,
        isProcessisSetWalkInOrderPaymentMethod: true,        
      };
    }

    case actionTypes.SET_WALKIN_ORDER_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        isSetWalkInOrderPaymentMethodSuccess: true,
        isProcessisSetWalkInOrderPaymentMethod: false,
      };
    }

    case actionTypes.SET_WALKIN_ORDER_PAYMENT_METHOD_FAIL: {
      return {
        ...state,
        isSetWalkInOrderPaymentMethodFailed: true,
        isProcessisSetWalkInOrderPaymentMethod: false,
      };
    }

    case actionTypes.COMPLETE_WALKIN_ORDER: {
      return {
        ...state,
        isWalkInOrderCompleteSuccess: false,
        isWalkInOrderCompleteFailed: false,
        isProcessCompleteWalkInOrder: true,        
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.COMPLETE_WALKIN_ORDER_SUCCESS: {
      return {
        ...state,
        pendingWalkInOrder: null,
        isWalkInOrderCompleteSuccess: true,
        isProcessCompleteWalkInOrder: false,
      };
    }

    case actionTypes.COMPLETE_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isWalkInOrderCompleteFailed: true,
        isProcessCompleteWalkInOrder: false,
      };
    }
    

    case actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER: {
      return {
        ...state,
        isGradeAgainWalkInOrderSuccess: false,
        isGradeAgainWalkInOrderFailed: false,
        isProcessGradeAgainCompleteWalkInOrder: true,        
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER_SUCCESS: {
      return {
        ...state,
        pendingWalkInOrder: null,
        isGradeAgainWalkInOrderSuccess: true,
        isProcessGradeAgainCompleteWalkInOrder: false,
      };
    }

    case actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isGradeAgainWalkInOrderFailed: true,
        isProcessGradeAgainCompleteWalkInOrder: false,
      };
    }
    

    case actionTypes.CANCEL_COMPLETED_WALKIN_ORDER: {
      return {
        ...state,
        isCancelCompletedWalkInOrderSuccess: false,
        isCancelCompletedWalkInOrderFailed: false,
        isProcessCancelCompletedWalkInOrder: true,        
        newWalkInOrderID: null,
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.CANCEL_COMPLETED_WALKIN_ORDER_SUCCESS: {
      return {
        ...state,
        isCancelCompletedWalkInOrderSuccess: true,
        isProcessCancelCompletedWalkInOrder: false,
        newWalkInOrderID: (action.data && action.data.order_id) ? action.data.order_id : null,
      };
    }

    case actionTypes.CANCEL_COMPLETED_WALKIN_ORDER_FAIL: {
      return {
        ...state,
        isCancelCompletedWalkInOrderFailed: true,
        isProcessCancelCompletedWalkInOrder: false,
      };
    }
    

    case actionTypes.WALKIN_ORDER_DETAIL: {
      return {
        ...state,
        pendingWalkInOrderDetail: cloneDeep(state.pendingWalkInOrderDetail),
        isLoadingWalkInOrderDetail: true,
        isWalkInOrderDetailUpdated: false,
      };
    }

    case actionTypes.WALKIN_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        pendingWalkInOrderDetail: action.data,
        isLoadingWalkInOrderDetail: false,
        isWalkInOrderDetailUpdated: true,
      };
    }

    case actionTypes.WALKIN_ORDER_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingWalkInOrderDetail: false
      };
    }

    
    

    case actionTypes.WALKIN_ORDER_RECYCLING_ADD: {
      return {
        ...state,
        isWalkInOrderRecyclingAddSuccess: false,
        isWalkInOrderRecyclingAddFailed: false,
        isProcessAddOrderRecycling: true,
        walkInOrderRecyclingPriceUpdNotif: null,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_ADD_SUCCESS: {
      return {
        ...state,
        isWalkInOrderRecyclingAddSuccess: true,
        isProcessAddOrderRecycling: false,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_ADD_FAIL: {
      return {
        ...state,
        isWalkInOrderRecyclingAddFailed: true,
        isProcessAddOrderRecycling: false,
        walkInOrderRecyclingPriceUpdNotif: null
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_PRICE_UPD_CONFIRM: {
      return {
        ...state,
        walkInOrderRecyclingPriceUpdNotif: action.data,
        isProcessAddOrderRecycling: false,
      };
    }
    

    case actionTypes.WALKIN_ORDER_RECYCLING_UPDATE: {
      return {
        ...state,
        isWalkInOrderRecyclingUpdateSuccess: false,
        isWalkInOrderRecyclingUpdateFailed: false,
        isProcessUpdateOrderRecycling: true,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_UPDATE_SUCCESS: {
      return {
        ...state,
        isWalkInOrderRecyclingUpdateSuccess: true,
        isProcessUpdateOrderRecycling: false,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_UPDATE_FAIL: {
      return {
        ...state,
        isWalkInOrderRecyclingUpdateFailed: true,
        isProcessUpdateOrderRecycling: false,
      };
    }
    
    

    case actionTypes.WALKIN_ORDER_RECYCLING_REMOVE: {
      return {
        ...state,
        isWalkInOrderRecyclingRemoveSuccess: false,
        isWalkInOrderRecyclingRemoveFailed: false,
        isProcessRemoveOrderRecycling: true,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_REMOVE_SUCCESS: {
      return {
        ...state,
        isWalkInOrderRecyclingRemoveSuccess: true,
        isProcessRemoveOrderRecycling: false,
      };
    }

    case actionTypes.WALKIN_ORDER_RECYCLING_REMOVE_FAIL: {
      return {
        ...state,
        isWalkInOrderRecyclingRemoveFailed: true,
        isProcessRemoveOrderRecycling: false,
      };
    }

    

    case actionTypes.WALKIN_ORDER_SET_ZONE: {
      return {
        ...state,
        isWalkInOrderSetZoneSuccess: false,
        isProcessWalkInOrderSetZone: true,
      };
    }

    case actionTypes.WALKIN_ORDER_SET_ZONE_SUCCESS: {
      return {
        ...state,
        isWalkInOrderSetZoneSuccess: true,
        isProcessWalkInOrderSetZone: false,
      };
    }

    case actionTypes.WALKIN_ORDER_SET_ZONE_FAIL: {
      return {
        ...state,
        isWalkInOrderSetZoneSuccess: false,
        isProcessWalkInOrderSetZone: false,
      };
    }
    

    case actionTypes.WALKIN_ORDER_CATEGORY_SET_ZONE: {
      return {
        ...state,
        isWalkInOrderCategorySetZoneSuccess: false,
        isProcessWalkInOrderCategorySetZone: true,
      };
    }

    case actionTypes.WALKIN_ORDER_CATEGORY_SET_ZONE_SUCCESS: {
      return {
        ...state,
        isWalkInOrderCategorySetZoneSuccess: true,
        isProcessWalkInOrderCategorySetZone: false,
      };
    }

    case actionTypes.WALKIN_ORDER_CATEGORY_SET_ZONE_FAIL: {
      return {
        ...state,
        isWalkInOrderCategorySetZoneSuccess: false,
        isProcessWalkInOrderCategorySetZone: false,
      };
    }
    
    /* ===== WALK IN ORDER  ===== */
    
    
    case actionTypes.ORDER_HISTORY_COUNT: {
      return {
        ...state,
        orderHistoryCount: {},
        isLoadingOrderHistoryCount: true,
      };
    }

    case actionTypes.ORDER_HISTORY_COUNT_SUCCESS: {
      return {
        ...state,
        orderHistoryCount: action.data,
        isLoadingOrderHistoryCount: false
      };
    }

    case actionTypes.ORDER_HISTORY_COUNT_FAIL: {
      return {
        ...state,
        isLoadingOrderHistoryCount: false
      };
    }


    case actionTypes.ORDER_HISTORY_LIST:{
      let orderHistoryList = cloneDeep(state.orderHistoryList);

      orderHistoryList.isLoading = true;

      if (action.data.offset === 0) {
        orderHistoryList.data = [];
      }
      
      return {
        ...state,
        isLoadingOrderHistoryList: true,
        isLoadOrderHistoryListDone: false,
        orderHistoryList,
        isRefreshingOrderHistoryList: (action.data.offset > 0) ? true : false,
        orderHistoryListFilter: action.data
      };
    }

    case actionTypes.ORDER_HISTORY_LIST_SUCCESS: {
      let orderHistoryList = {};
      
      if (action.data.limit === undefined) {
        orderHistoryList = cloneDeep(state.orderHistoryList);
        orderHistoryList.data = action.data.data;
      } else {
        orderHistoryList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          orderHistoryList.data = action.data.data;
          orderHistoryList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            orderHistoryList.data = [...state.orderHistoryList.data];
            orderHistoryList.offset = action.data.offset;
          } else {
            let temp = [...state.orderHistoryList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            orderHistoryList.data = temp;
            orderHistoryList.length = state.orderHistoryList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          orderHistoryList.offset = orderHistoryList.length;
        }
      }

      orderHistoryList.isLoading = false;
      orderHistoryList.total = action.data.total;
      orderHistoryList.hasMore = false;
      
      if(orderHistoryList.length < orderHistoryList.total)
      {
        orderHistoryList.hasMore = true;
      }

      return {
        ...state,
        isLoadingOrderHistoryList: false,
        isLoadOrderHistoryListDone: true,
        isLoadingOrderUpdate: false,
        orderHistoryList,
      };
    }

    case actionTypes.ORDER_HISTORY_LIST_FAIL:{
      let orderHistoryList = cloneDeep(state.orderHistoryList);
      orderHistoryList.isLoading = false;

      return {
        ...state,
        orderHistoryList,
        isLoadingOrderHistoryList: false,
        isLoadOrderHistoryListDone: true,
      };
    }



    case actionTypes.ORDER_HISTORY_DETAIL: {
      return {
        ...state,
        orderHistoryDetail: {},
        isLoadingOrderHistoryDetail: true,
      };
    }

    case actionTypes.ORDER_HISTORY_DETAIL_SUCCESS: {
      return {
        ...state,
        orderHistoryDetail: action.data,
        isLoadingOrderHistoryDetail: false
      };
    }

    case actionTypes.ORDER_HISTORY_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingOrderHistoryDetail: false
      };
    }


  
  
    /* ===== GRADING TASK  ===== */
    case actionTypes.GRADING_TASK_LIST:{
      let gradingTaskList = cloneDeep(state.gradingTaskList);
  
      gradingTaskList.isLoading = true;

      if (action.data.offset === 0) {
        gradingTaskList.data = [];
      }

      return {
        ...state,
        isLoadingGradingTaskList: true,
        gradingTaskList,
        isRefreshingGradingTaskList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.GRADING_TASK_LIST_SUCCESS: {
      let gradingTaskList = {};
      
      if (action.data.limit === undefined) {
        gradingTaskList = cloneDeep(state.gradingTaskList);
        gradingTaskList.data = action.data.data;
      } else {
        gradingTaskList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          gradingTaskList.data = action.data.data;
          gradingTaskList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            gradingTaskList.data = [...state.gradingTaskList.data];
            gradingTaskList.offset = action.data.offset;
          } else {
            let temp = [...state.gradingTaskList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            gradingTaskList.data = temp;
            gradingTaskList.length = state.gradingTaskList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          gradingTaskList.offset = gradingTaskList.length;
        }
      }
  
      gradingTaskList.isLoading = false;
      gradingTaskList.total = action.data.total;
      gradingTaskList.hasMore = false;
      
      if(gradingTaskList.length < gradingTaskList.total)
      {
        gradingTaskList.hasMore = true;
      }

      return {
        ...state,
        isLoadingGradingTaskList: false,
        isLoadingOrderUpdate: false,
        gradingTaskList
      };
    }

    case actionTypes.GRADING_TASK_LIST_FAIL:{
      let gradingTaskList = cloneDeep(state.gradingTaskList);
      gradingTaskList.isLoading = false;

      return {
        ...state,
        gradingTaskList,
        isLoadingGradingTaskList: false,
      };
    }



    case actionTypes.GRADING_TASK_DETAIL: {
      return {
        ...state,
        gradingTaskDetail: {},
        isLoadingGradingTaskDetail: true,
      };
    }

    case actionTypes.GRADING_TASK_DETAIL_SUCCESS: {
      return {
        ...state,
        gradingTaskDetail: action.data,
        isLoadingGradingTaskDetail: false
      };
    }

    case actionTypes.GRADING_TASK_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingGradingTaskDetail: false
      };
    }
    

    

    case actionTypes.GRADING_TASK_ACCEPT: {
      return {
        ...state,
        isGradingTaskAcceptSuccess: false,
        isProcessAcceptGradingTask: true,
      };
    }

    case actionTypes.GRADING_TASK_ACCEPT_SUCCESS: {
      return {
        ...state,
        isGradingTaskAcceptSuccess: true,
        isProcessAcceptGradingTask: false
      };
    }

    case actionTypes.GRADING_TASK_ACCEPT_FAIL: {
      return {
        ...state,
        isGradingTaskAcceptSuccess: false,
        isProcessAcceptGradingTask: false
      };
    }
    

    case actionTypes.GRADING_TASK_REJECT: {
      return {
        ...state,
        isGradingTaskRejectSuccess: false,
        isProcessRejectGradingTask: true,
      };
    }

    case actionTypes.GRADING_TASK_REJECT_SUCCESS: {
      return {
        ...state,
        isGradingTaskRejectSuccess: true,
        isProcessRejectGradingTask: false
      };
    }

    case actionTypes.GRADING_TASK_REJECT_FAIL: {
      return {
        ...state,
        isGradingTaskRejectSuccess: false,
        isProcessRejectGradingTask: false
      };
    }
    
    

    case actionTypes.GRADING_TASK_SET_ZONE: {
      return {
        ...state,
        isGradingTaskSetZoneSuccess: false,
        isProcessGradingTaskSetZone: true,
      };
    }

    case actionTypes.GRADING_TASK_SET_ZONE_SUCCESS: {
      return {
        ...state,
        isGradingTaskSetZoneSuccess: true,
        isProcessGradingTaskSetZone: false
      };
    }

    case actionTypes.GRADING_TASK_SET_ZONE_FAIL: {
      return {
        ...state,
        isGradingTaskSetZoneSuccess: false,
        isProcessGradingTaskSetZone: false
      };
    }
    

    case actionTypes.GRADING_TASK_CATEGORY_SET_ZONE: {
      return {
        ...state,
        isGradingTaskCategorySetZoneSuccess: false,
        isProcessGradingTaskCategorySetZone: true,
      };
    }

    case actionTypes.GRADING_TASK_CATEGORY_SET_ZONE_SUCCESS: {
      return {
        ...state,
        isGradingTaskCategorySetZoneSuccess: true,
        isProcessGradingTaskCategorySetZone: false
      };
    }

    case actionTypes.GRADING_TASK_CATEGORY_SET_ZONE_FAIL: {
      return {
        ...state,
        isGradingTaskCategorySetZoneSuccess: false,
        isProcessGradingTaskCategorySetZone: false
      };
    }
    
    
    case actionTypes.GRADING_TASK_RECYCLING_ADD: {
      return {
        ...state,
        isGradingTaskRecyclingAddSuccess: false,
        isProcessAddGradingTaskRecycling: true,
        gradingTaskRecyclingPriceUpdNotif: null,
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_ADD_SUCCESS: {
      return {
        ...state,
        isGradingTaskRecyclingAddSuccess: true,
        isProcessAddGradingTaskRecycling: false
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_ADD_FAIL: {
      return {
        ...state,
        isGradingTaskRecyclingAddSuccess: false,
        isProcessAddGradingTaskRecycling: false
      };
    }


    case actionTypes.GRADING_TASK_RECYCLING_PRICE_UPD_CONFIRM: {
      return {
        ...state,
        gradingTaskRecyclingPriceUpdNotif: action.data,
        isProcessAddGradingTaskRecycling: false,
      };
    }

    

    case actionTypes.GRADING_TASK_RECYCLING_UPDATE: {
      return {
        ...state,
        isGradingTaskRecyclingUpdateSuccess: false,
        isProcessUpdateGradingTaskRecycling: true,
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_UPDATE_SUCCESS: {
      return {
        ...state,
        isGradingTaskRecyclingUpdateSuccess: true,
        isProcessUpdateGradingTaskRecycling: false
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_UPDATE_FAIL: {
      return {
        ...state,
        isGradingTaskRecyclingUpdateSuccess: false,
        isProcessUpdateGradingTaskRecycling: false
      };
    }

    

    case actionTypes.GRADING_TASK_RECYCLING_REMOVE: {
      return {
        ...state,
        isGradingTaskRecyclingRemoveSuccess: false,
        isProcessRemoveGradingTaskRecycling: true,
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_REMOVE_SUCCESS: {
      return {
        ...state,
        isGradingTaskRecyclingRemoveSuccess: true,
        isProcessRemoveGradingTaskRecycling: false
      };
    }

    case actionTypes.GRADING_TASK_RECYCLING_REMOVE_FAIL: {
      return {
        ...state,
        isGradingTaskRecyclingRemoveSuccess: false,
        isProcessRemoveGradingTaskRecycling: false
      };
    }



    

    case actionTypes.GRADING_TASK_UPLOAD_DOC: {
      return {
        ...state,
        isGradingTaskDocumentUploadSuccess: false,
        isProcessUploadGradingTaskDocument: true,
      };
    }

    case actionTypes.GRADING_TASK_UPLOAD_DOC_SUCCESS: {
      return {
        ...state,
        isGradingTaskDocumentUploadSuccess: true,
        isProcessUploadGradingTaskDocument: false
      };
    }

    case actionTypes.GRADING_TASK_UPLOAD_DOC_FAIL: {
      return {
        ...state,
        isGradingTaskDocumentUploadSuccess: false,
        isProcessUploadGradingTaskDocument: false
      };
    }

    

    case actionTypes.COMPLETE_GRADING_TASK: {
      return {
        ...state,
        isGradingTaskCompleteSuccess: false,
        isProcessCompleteGradingTask: true,        
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.COMPLETE_GRADING_TASK_SUCCESS: {
      return {
        ...state,
        isGradingTaskCompleteSuccess: true,
        isProcessCompleteGradingTask: false
      };
    }

    case actionTypes.COMPLETE_GRADING_TASK_FAIL: {
      return {
        ...state,
        isGradingTaskCompleteSuccess: false,
        isProcessCompleteGradingTask: false
      };
    }
    
    /* ===== GRADING TASK  ===== */


  
    /* ===== DUMPWASTE GRADING TASK  ===== */
    case actionTypes.WASTE_GRADING_TASK_LIST:{
      let wasteGradingTaskList = cloneDeep(state.wasteGradingTaskList);
  
      wasteGradingTaskList.isLoading = true;

      if (action.data.offset === 0) {
        wasteGradingTaskList.data = [];
      }

      return {
        ...state,
        isLoadingWasteGradingTaskList: true,
        wasteGradingTaskList,
        isRefreshingWasteGradingTaskList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.WASTE_GRADING_TASK_LIST_SUCCESS: {
      let wasteGradingTaskList = {};
      
      if (action.data.limit === undefined) {
        wasteGradingTaskList = cloneDeep(state.wasteGradingTaskList);
        wasteGradingTaskList.data = action.data.data;
      } else {
        wasteGradingTaskList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          wasteGradingTaskList.data = action.data.data;
          wasteGradingTaskList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            wasteGradingTaskList.data = [...state.wasteGradingTaskList.data];
            wasteGradingTaskList.offset = action.data.offset;
          } else {
            let temp = [...state.wasteGradingTaskList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            wasteGradingTaskList.data = temp;
            wasteGradingTaskList.length = state.wasteGradingTaskList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          wasteGradingTaskList.offset = wasteGradingTaskList.length;
        }
      }
  
      wasteGradingTaskList.isLoading = false;
      wasteGradingTaskList.total = action.data.total;
      wasteGradingTaskList.hasMore = false;
      
      if(wasteGradingTaskList.length < wasteGradingTaskList.total)
      {
        wasteGradingTaskList.hasMore = true;
      }

      return {
        ...state,
        isLoadingWasteGradingTaskList: false,
        isLoadingOrderUpdate: false,
        wasteGradingTaskList
      };
    }

    case actionTypes.WASTE_GRADING_TASK_LIST_FAIL:{
      let wasteGradingTaskList = cloneDeep(state.wasteGradingTaskList);
      wasteGradingTaskList.isLoading = false;

      return {
        ...state,
        wasteGradingTaskList,
        isLoadingWasteGradingTaskList: false,
      };
    }



    case actionTypes.WASTE_GRADING_TASK_DETAIL: {
      return {
        ...state,
        wasteGradingTaskDetail: {},
        isLoadingWasteGradingTaskDetail: true,
      };
    }

    case actionTypes.WASTE_GRADING_TASK_DETAIL_SUCCESS: {
      return {
        ...state,
        wasteGradingTaskDetail: action.data,
        isLoadingWasteGradingTaskDetail: false
      };
    }

    case actionTypes.WASTE_GRADING_TASK_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingWasteGradingTaskDetail: false
      };
    }
    

    

    case actionTypes.WASTE_GRADING_TASK_UPLOAD_DOC: {
      return {
        ...state,
        isWasteGradingTaskDocumentUploadSuccess: false,
        isProcessUploadWasteGradingTaskDocument: true,
      };
    }

    case actionTypes.WASTE_GRADING_TASK_UPLOAD_DOC_SUCCESS: {
      return {
        ...state,
        isWasteGradingTaskDocumentUploadSuccess: true,
        isProcessUploadWasteGradingTaskDocument: false
      };
    }

    case actionTypes.WASTE_GRADING_TASK_UPLOAD_DOC_FAIL: {
      return {
        ...state,
        isWasteGradingTaskDocumentUploadSuccess: false,
        isProcessUploadWasteGradingTaskDocument: false
      };
    }

    

    case actionTypes.COMPLETE_WASTE_GRADING_TASK: {
      return {
        ...state,
        isWasteGradingTaskCompleteSuccess: false,
        isProcessCompleteWasteGradingTask: true,        
        settlementClosed: {status: false, title: "", msg: ""},
      };
    }

    case actionTypes.COMPLETE_WASTE_GRADING_TASK_SUCCESS: {
      return {
        ...state,
        isWasteGradingTaskCompleteSuccess: true,
        isProcessCompleteWasteGradingTask: false
      };
    }

    case actionTypes.COMPLETE_WASTE_GRADING_TASK_FAIL: {
      return {
        ...state,
        isWasteGradingTaskCompleteSuccess: false,
        isProcessCompleteWasteGradingTask: false
      };
    }
    
    /* ===== DUMPWASTE GRADING TASK  ===== */




    case actionTypes.REGISTER_USER: {
      return {
        ...state,
        isRegisterUserSuccess: false,
        isProcessRegisterUser: true,    
        userExist: false,    
      };
    }

    case actionTypes.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        isRegisterUserSuccess: (action.data && action.data.user_exist) ? false : true,
        isProcessRegisterUser: false,
        userExist: (action.data && action.data.user_exist) ? true : false,
      };
    }

    case actionTypes.REGISTER_USER_FAIL: {
      return {
        ...state,
        isRegisterUserSuccess: false,
        isProcessRegisterUser: false
      };
    }

    

    case actionTypes.CHECK_USER_EXIST: {
      return {
        ...state,  
        userExist: false,
        userExistDetail: null,
        isProcessCheckUserExist: true,
        userNotFound: false,
      };
    }

    case actionTypes.CHECK_USER_EXIST_SUCCESS: {

      return {
        ...state,
        userExist: (action.data && action.data.user_exist) ? true : false,
        userExistDetail: (action.data && action.data.user_exist) ? action.data.client : null,
        isProcessCheckUserExist: false,
        userNotFound: (action.data && !action.data.user_exist) ? true : false,
      };
    }

    case actionTypes.CHECK_USER_EXIST_FAIL: {
      return {
        ...state,
        isProcessCheckUserExist: false,
      };
    }
    

    case actionTypes.RESET_USER_EXIST_SUCCESS:
      
      return {
        ...state,
        userExist: false,
        userExistDetail: null,
        userNotFound: false,
        isProcessCheckUserExist: false,
      };
      

    
    default:
      return state;
  }
}
